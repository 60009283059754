<template>
  <div class="detail-content">
    <div class="title">{{title}}</div>
    <div class="main-content">
      <div class="main-left">
        <div  v-for="(value,key) in labelProp" :key="key">
            <div class="item-wrap" v-if="datas[key]">
                <div class="item-label">{{value}}:</div>
                <slot :name="key">
                  <div class="item-value" v-html="datas[key]"></div>
                </slot>
            </div>
        </div>
      </div>
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    datas: {
      type: Object,
      default() {
        return {}
      }
    },
    labelProp: {
      type: Object,
      default() {
        return {
          txId: '哈希值',
          blockNumber: '区块高度(NFT)',
          txTime: '上链时间',
          type: '数据类型',
          inActAmount: '交易金额',
          content: '内容',
          feeAmount: '手续费'
        }
      }
    }
  },
  data() {
    return {
    }
  }
}
</script>

<style lang="less" scoped>
@import './title.less';
.detail-content{
  padding: 24px;
  background: #fff;
  margin-bottom: 16px;
  .main-content{
    margin-top: 24px;
    display: flex;
    .main-left{
      flex: 1;
    }
  }
  .item-wrap{
    display: flex;
    align-items: flex-start;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 24px;
    .item-label{
      width: 146px;
      color: #999999;
    }
    .item-value{
      flex: 1;
      word-break: break-all;
      color:#333;
    }
  }
}

@media screen and (max-width: 1024px) {
 .detail-content{
   padding: 16px;
   margin-bottom: 8px;
   .main-content{
    margin-top: 16px;
    flex-direction: column-reverse;
    align-items: center;
    .main-left{
      width: 100%;
    }
   }
   .item-wrap{
    margin-bottom: 16px;
    line-height: 22px;
    font-size: 14px;
    .item-label{
      width: 107px;
    }
   }
 }
}
</style>
