<template>
  <div>
    <detailContent title="数据详情" :datas="dataDetail"></detailContent>
    <detailContent
      title="NFT 详情"
      :datas="nftInfo"
      :labelProp="nftDetailConfig"
      v-if="nftInfo"
    >
      <template #right v-if="nftInfo.memberCardBackgroundUrl">
        <div class="ml-24">
          <div
            :style="{
              'background-image': `url(${nftInfo.memberCardBackgroundUrl})`
            }"
            :class="[nftInfo.nftType == 1 ? 'play-img' : 'nft-img', 'nft-bg']"
          ></div>
        </div>
      </template>
    </detailContent>
  </div>
</template>

<script>
import detailContent from '@/components/data/detailContent'
import api from '@/api/services/browser'
import detailConfig from '@/components/data/detailConfig'

export default {
  components: {
    detailContent
  },
  data() {
    return {
      hash: '',
      dataDetail: {},
      nftInfo: null,
      source: '', // 如果是通兑吧跳过来带tongdui8
      type: '', //    adf: 'ADFun区块',nft: 'NFT区块'
      nftDetailConfig: detailConfig.nftDetailConfig,
      blockHash: ''
    }
  },
  watch: {
    '$route.query': {
      handler(val) {
        this.$route.meta.breadList.splice(0, 1, {
          name: '区块浏览器',
          path: `/homeData?type=${val.type}`
        })
      },
      immediate: true,
      deep: true
    }
  },
  beforeRouteEnter: (to, from, next) => {
    // ...
    if (from.name === 'moreBlockData') {
      to.meta.breadList = [
        {
          name: '区块浏览器',
          path: '/homeData'
        },
        {
          name: '更多区块数据',
          path: '/moreBlockData'
        },
        {
          name: '区块详情',
          path: `/hashBlock?blockHash=${to.query.blockHash}&type=${to.query.type}`
        },
        {
          name: '数据详情',
          path: ''
        }
      ]
    } else {
      const metaList = [
        {
          name: '区块浏览器',
          path: '/homeData'
        },
        {
          name: '数据详情',
          path: ''
        }
      ]
      const blocklist = [
        {
          name: '区块浏览器',
          path: '/homeData'
        },
        {
          name: '区块详情',
          path: `/hashBlock?blockHash=${to.query.blockHash}&type=${to.query.type}`
        },
        {
          name: '数据详情',
          path: ''
        }
      ]
      to.meta.breadList = to.query.source === 'tongdui8' ? metaList : blocklist
      // console.log(metaList, metaList.splice(1, 1))
    }
    next()
  },
  created() {
    const query = this.$route.query
    this.txId = query.txId
    this.blockHash = query.blockHash
    this.type = query.type
    this.source = query.source
    this.getByBlockHash()
  },
  methods: {
    async getByBlockHash() {
      const requestApi =
        this.source === 'tongdui8'
          ? api.selectByConditions
          : api.getByTransferHash
      const query =
        this.source === 'tongdui8'
          ? {
              condition: this.blockHash,
              page: 1,
              size: 10
            }
          : {
              txId: this.txId,
              type: this.type
            }
      const data = await requestApi(query)
      const detail = (data && data.detail) || {}

      this.dataDetail = detail
      if (data.phpNftTokenId || data.nftSymbol) {
        this.getNFTDetail(data)
      }
    },

    async getNFTDetail(data) {
      const fn = data.phpNftTokenId
        ? api.getNFTDetailById
        : api.getNFTDetailBySymbol
      const param = {
        s_ver: 1
      }
      let result = null
      if (data.phpNftTokenId) {
        param.tokenId = data.phpNftTokenId
      } else {
        param.symbol = data.nftSymbol
      }
      result = await fn({ ...param })
      if (result.nftType == 0) {
        result.cardName = result.nftName
        result.cardDesc = result.desc
        delete result.nftName
        delete result.desc
      }
      if (result && result.originPrice) {
        result.originPrice =
          result.nftType == 1
            ? `${result.originPrice} ADF`
            : `￥ ${result.originPrice / 100}`
      }

      this.nftInfo = result
    }
  }
}
</script>

<style lang="less" scoped></style>
