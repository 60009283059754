const detailConfig = {
    txId: '哈希值',
    blockNumber: '区块高度(NFT)',
    txTime: '上链时间',
    type: '数据类型',
    inActAmount: '交易金额',
    content: '内容',
    feeAmount: '手续费',
    blockHash: '哈希值',
    blockSize: '储存数量'
  },
  nftDetailConfig = {
    tokenId: 'NFT ID',
    nftName: '数字藏品名称',
    cradName: '会员卡名称',
    issuerInfo: '发行方',
    originPrice: '价值',
    memberRights: '会员权益',
    cardDesc: '会员卡介绍',
    desc: '数字藏品介绍'
  }
export default {
  detailConfig,
  nftDetailConfig
}
